import React, { useState, useEffect } from 'react'
import Select from "react-select";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function ReservationItem(props) {
    const { position, onDelete, data, onChange, customers, bedrooms } = props
    const [bedroom, setBedroom] = useState(0)
    const [customer, setCustomer] = useState([])

    useEffect(() => {
        onChange(position, {
            bedroom: bedroom,
            customer: customer,
        });
    }, [bedroom, customer]);

    function onChooseBedroom(params) {
        setBedroom(params.value)
    }

    function onChooseCustomer(params) {
        let a = []
        if (params) {
            params.forEach((item) => {
                a.push(item.value)
            })
        }
        setCustomer(a)
    }


    return (<div className="card card-body mt-2 ">
        <div className="row ">
            <div className="col">
                <label htmlFor="exampleInputEmail1" className="form-label">Chambre <span
                    style={{ color: "red" }}>*</span></label>
                <Select onChange={onChooseBedroom} options={bedrooms} />
            </div>
            <div className="col">
                <label htmlFor="exampleInputEmail1" className="form-label">Clients<span
                    style={{ color: "red" }}>*</span></label>
                <Select onChange={onChooseCustomer} options={customers} isMulti />
            </div>
            <div className="col-lg-1 align-content-center align-items-center justify-content-center">
                {
                    !position == 0 && <DeleteOutlineIcon onClick={() => onDelete(position)} />
                }
            </div>
        </div>
    </div>)
}


