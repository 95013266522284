import React, { useState, useEffect } from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import { Link, useHistory, useParams } from "react-router-dom";
import { useAlert } from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";

export default function EditBedroom() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        { id } = useParams(),
        [data, setData] = useState({}),
        { push } = useAlert();

    async function onGet() {
        let res = await resources.bedroom.readOne(id)
        if (res.status === 200) setData(res.data)
    }

    useEffect(() => {
        onGet();
    }, [id])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        let formData = new FormData(e.target)
        setLoader(true)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let res = await resources.bedroom.update(id, object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Chambre {id}</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/articles">Articles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">chambre {id}</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><SaveIcon /> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" defaultValue={data.name} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Numéro</label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="num" defaultValue={data.num} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Prix <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                name="price" defaultValue={data.price} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                className="form-label">Note</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                rows="3" name="note" defaultValue={data.note} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}


