import React from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";
import invoice from "../../services/invoice";
import PaymentIcon from '@material-ui/icons/Payment';

function InvoicePage() {
    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Factures</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Factures</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/invoice/create"><AddIcon/> Nouvelle
                                facture</Link>
                        </div>
                    </div>
                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Client", field: "customer", render: ["fullName"]},
                            {
                                label: "Status", field: "status", renderStyle: (data) => {
                                    return <p>{data.status === 0 &&
                                    <span className="badge bg-warning text-dark">en attente de paiment</span>}
                                        {data.status === 1 &&
                                        <span className="badge bg-primary">en partie payer</span>}
                                        {data.status === 2 &&
                                        <span className="badge bg-success">paiment complet</span>}
                                        {data.status === 3 &&
                                        <span className="badge bg-danger">retard</span>}</p>
                                }
                            },
                            {label: "Reduction (%)", field: "discount"},
                            {
                                label: "Total", field: "total", renderStyle: (data) => {
                                    return <p>
                                        {data.total - (data.total * data.discount) / 100}
                                    </p>
                                }
                            },
                            {label: "Date d'echenace", field: "dueDate"},
                        ]}
                                   element={"invoice"}
                                   resource={resources.invoice}
                                   action={(data) => {
                                       let customerId = data.customer.id
                                       let customerName = data.customer.fullName
                                       let res = parseInt(data.total) - parseInt(data.solde ? data.solde : "0")
                                       return (
                                           <li><Link
                                               className="dropdown-item"
                                               to={"/payment/create/" + data.id + "/" + res + "/" + customerId + "/" + customerName}>
                                               <PaymentIcon/> Passé au paiment</Link>
                                           </li>
                                       )
                                   }}
                                   view
                                   update
                                   deleteItem/>
                    </div>


                </main>
            </div>
        </div>

    </div>)
}

export default InvoicePage
