import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import config from "../constants";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Header() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isLogin = () => {
        if(!sessionStorage.getItem("user")){
          // alert("Votre session a expiré")
          window.location.replace("#/");
        }
    }

    const logout = () => {
        localStorage.clear()
        window.location.replace("#/");
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom">
            <div className="container">
                <Link className="navbar-brand" to="/dashboard">{config.app.name}</Link>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            {/* <a className="nav-link" data-bs-toggle="modal" onClick={handleClickOpen}>
                                <AccountBalanceIcon style={{fontSize: 30}}/>
                            </a> */}
                            <Dialog
                                open={open}
                                keepMounted
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle
                                    id="alert-dialog-slide-title">{"Convertisseur"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        <div>
                                            <h6>Taux</h6>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Monaie
                                                        de base</label>
                                                    <input type="text" className="form-control"
                                                           id="exampleFormControlInput1"/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Monaie
                                                        de base</label>
                                                    <input type="text" className="form-control"
                                                           id="exampleFormControlInput1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>


                        </li>
                        <li className="nav-item dropstart">
                            <a className="nav-link " href="#" id="navbarDropdown" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <AccountBoxIcon style={{fontSize: 30}}/>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/settings">Réglages</Link></li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li><a className="dropdown-item" onClick={logout}><ExitToAppIcon/> Déconnexion</a></li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header
