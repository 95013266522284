import React from 'react'
import config from "../../constants";

export default function Reservation1({ data, items }) {
    return (
        <div className="card border-0 ">
            <div className="card-body">
                <div>
                    <h1 className="fw-bold text-center mt-5 mb-5 color-app-default">{config.app.name}</h1>
                    <h6 className="fw-bold text-center">..., Av... , Quartier...- Commune de...</h6>
                    <h6 className="fw-bold text-center">R.C.C.M: </h6>
                    <h6 className="fw-bold text-center">N Impot: </h6>
                    <h5 className="fw-bold text-center mb-5">REPUBLIQUE DEMOCRATIQUE DU CONGO</h5>
                    <hr />
                </div>

                <div>
                    <h6> {data?.customer?.fullName} </h6>
                    <p className="text-muted col-lg-5">{data?.customer?.address} {data?.customer?.address1} {data?.customer?.city} {data?.customer?.country}</p>
                    <h6>du {data?.dateBegin} au {data?.dateEnd} </h6>
                </div>

                <div className="mt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Chambre</th>
                                <th scope="col">Client</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && items.map((el, key) => {
                                    return <tr key={key}>
                                        <td>{el?.bedroom?.name}</td>
                                        <td><ul>
                                            {el?.customer && el?.customer.map((ob, t) => {
                                                return <li key={t}>{ob.fullName}</li>
                                            })}
                                        </ul></td>
                                    </tr>
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    )
}
