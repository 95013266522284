import React, { useState, useEffect } from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";
import { useAlert } from "../../ui/Alert";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

function ArticlePage() {
    const [change, setChange] = useState(0);
    const { push } = useAlert()

    async function updateBedroom(el, value, callback) {
        let res = await resources.bedroom.update(el, {
            disponible: value
        })
        if ((await res).status === 201) {
            callback()
            push({
                success: true
            })
        }
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Articles</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to={change == 0 ? "/article/create" : "/bedroom/create"}><AddIcon /> Nouvelle {change == 0 && "article"} {change == 1 && "chambre"} </Link>
                        </div>
                    </div>

                    <div className="col mt-5">

                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => setChange(0)} >Divers</button>
                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => setChange(1)} >Chambres</button>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <DataTable column={[
                                    { label: "Nom", field: "name" },
                                    { label: "Prix", field: "price" },
                                ]}
                                    element={"article"}
                                    resource={resources.article}
                                    action={() => { }}
                                    update
                                    deleteItem />
                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <DataTable column={[
                                    { label: "Nom", field: "name" },
                                    { label: "Numéro", field: "num" },
                                    { label: "Note", field: "note" },
                                    { label: "Prix", field: "price" },
                                    {
                                        label: "Status", field: "disponible", renderStyle: (data) => {
                                            return <p>
                                                {data.disponible === true &&
                                                    <span className="badge bg-primary">disponible</span>}
                                                {data.disponible === false &&
                                                    <span className="badge bg-danger">occupée</span>}</p>
                                        }
                                    },
                                ]}
                                    element={"bedroom"}
                                    resource={resources.bedroom}
                                    action={(data, callback) => {
                                        return <>
                                            {
                                                data?.disponible ? (<li onClick={() => updateBedroom(data.id, false, callback)}><span
                                                    className="dropdown-item" style={{ cursor: "pointer" }}>
                                                    <CancelIcon /> Marqué occupée</span>
                                                </li>) : (<li onClick={() => updateBedroom(data.id, true, callback)}><span
                                                    className="dropdown-item" style={{ cursor: "pointer" }}>
                                                    <CheckCircleIcon /> Marqué disponible</span>
                                                </li>)
                                            }

                                        </>
                                    }}
                                    update
                                    deleteItem />
                            </div>
                        </div>



                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default ArticlePage
