import React, { useState, useEffect } from 'react'
import Sidebar from "../../composents/Sidebar";
import AddIcon from '@material-ui/icons/Add';
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";
import Select from "react-select";
import ReservationItem from '../../composents/ReservationItem';

function CreateProject() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        { push } = useAlert(),
        [customers, setCustomers] = useState([]),
        [customer, setCustomer] = useState(),
        [invoices, setInvoices] = useState([]),
        [invoice, setInvoice] = useState(),
        [bedrooms, setBedrooms] = useState([]),
        [items, setItems] = useState([{
            bedroom: 0,
            customer: [],
        }])

    async function onGet() {
        let pro = await resources.invoice.read()
        if (pro.status === 200) {
            let a = []
            pro.data.items.forEach((el) => {
                if (el.status !== 2) {
                    a.push({
                        value: el.id,
                        label: el.id,
                    })
                }

            })
            setInvoices(a)
        }


        let cus = await resources.customer.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.fullName,
                })
            })
            setCustomers(a)
        }


        let bed = await resources.bedroom.readBy("?disponible=1")
        if (bed.status === 200) {
            let a = []
            bed.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setBedrooms(a)
        }



    }

    function onAddItem() {
        setItems(e => [...e, {
            bedroom: [],
            customer: [],
        }])
    }

    function onHandleChange(position, e) {
        const a = items;
        a.forEach((el, key) => {
            if (position == key) {
                el.bedroom = e.bedroom;
                el.customer = e.customer;
            }
        });

        setItems(a);
        console.log(a)
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)

        let object = {
            customer: customer,
            dateBegin: e.target.dateBegin.value,
            dateEnd: e.target.dateEnd.value,
            items: items
        };

        if (invoice) {
            object.invoice = invoice
        }

        let res = await resources.reservation.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }

        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouvelle reservation</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/projects">Reservations</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouvelle reservation</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><SaveIcon /> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-10 ">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Date début <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                                        required name="dateBegin" />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Date fin <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                                        required name="dateEnd" />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Facture <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <Select onChange={(e) => setInvoice(e.value)} options={invoices} />
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Client <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <Select onChange={(e) => setCustomer(e.value)} options={customers} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {items.map((item, key) => {
                                    return <ReservationItem key={key} position={key} data={item} customers={customers} bedrooms={bedrooms} onChange={onHandleChange} />
                                })}

                                <button className="btn btn-light w-100 mt-3" type="button" onClick={onAddItem}>
                                    <AddIcon /> AJOUTER UNE LIGNE
                                </button>

                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreateProject
