import React, {useState, useEffect, useRef} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import PrintIcon from '@material-ui/icons/Print';
import {Link, useParams} from "react-router-dom";
import resources from "../../services";
import Facture1 from "../../composents/modeles/Facture1";
import ReactToPrint from "react-to-print";
import Btn from "../../ui/Btn";
import Reservation1 from '../../composents/modeles/Reservation1';

export default function ViewProject() {
    const {id} = useParams(), [data, setData] = useState({}), componentRef = useRef();

    async function onGet() {
        let res = await resources.reservation.readOne(id)
        if (res.status === 200) {
            setData(res.data)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Reservation {id}</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted"
                                        to="/projects">Reservations</Link></li>
                                </ol>
                            </nav>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Btn
                                            className="btn btn-app-default btn-sm"
                                        >
                                            <PrintIcon/> Imprimer
                                        </Btn>
                                    );
                                }}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                    <div className="col mt-5 mb-5">
                        <div className="" ref={componentRef}>
                            <Reservation1 data={data} items={data?.reservationItems ? data?.reservationItems : []}/>
                        </div>

                    </div>

                </main>
            </div>
        </div>

    </div>)
}

