import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import {Link, useHistory, useParams} from "react-router-dom";
import {useAlert} from "../../ui/Alert";
import resources from "../../services";
import Select from "react-select";
import Btn from "../../ui/Btn";

function CreatePayment() {
    const {invoiceId, rest, customerId, customerName} = useParams()
    const [loader, setLoader] = useState(false),
        history = useHistory(), {push} = useAlert(),
        [customers, setCustomers] = useState([]),
        [invoices, setInvoices] = useState([]),
        [invoice, setInvoice] = useState(invoiceId && invoiceId),
        [customer, setCustomer] = useState(customerId && customerId);


    async function onGet() {
        let pro = await resources.invoice.read()
        if (pro.status === 200) {
            let a = []
            pro.data.items.forEach((el) => {
                if (el.status !== 2) {
                    a.push({
                        value: el.id,
                        label: "INV " + el.id,
                    })
                }

            })
            setInvoices(a)
        }


        let cus = await resources.customer.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.fullName,
                })
            })
            setCustomers(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            customer: customer,
            amount: e.target.amount.value,
            invoice: invoice,
            note: e.target.note.value,
            methode: e.target.methode.value
        };

        let res = await resources.payment.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <form onSubmit={onCreate}>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouveau paiement</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/payments">Paiements</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouveau paiement</li>
                                    </ol>
                                </nav>
                                <Btn onLoad={loader} className="btn btn-app-default btn-sm"
                                     type="submit"><SaveIcon/> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Client</label>
                                            <Select onChange={(e) => setCustomer(e.value)} defaultValue={customerId && {
                                                label: customerName,
                                                value: customerId
                                            }} options={customers}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                   className="form-label">Facture</label>
                                            <Select onChange={(e) => setInvoice(e.value)} defaultValue={invoiceId && {
                                                label: "INV " + invoiceId,
                                                value: invoiceId
                                            }} options={invoices}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Montant <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="amount" defaultValue={rest && rest} required/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Methode de
                                                paiment <span style={{color: "red"}}>*</span></label>
                                            <select className="form-select" aria-label="Default select example"
                                                    name="methode" required>
                                                <option selected>Cash</option>
                                                <option>Banque</option>
                                                <option>Cheque</option>
                                                <option>Carte de crédit</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="form-label">Note</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                      rows="3" name="note"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreatePayment
