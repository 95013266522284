import React, {useState} from 'react'
import {useHistory} from "react-router-dom";
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import {Link} from "react-router-dom";
import resources from "../../services";
import Btn from "../../ui/Btn";
import {useAlert} from "../../ui/Alert";

function CreateCustomer() {
    const [loader, setLoader] = useState(false), history = useHistory(), {push} = useAlert();

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        let formData = new FormData(e.target)
        setLoader(true)
        let res = await resources.customer.create(formData)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <form onSubmit={onCreate}>
                <div className="row">
                    <Sidebar/>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouveau client</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/customers">Clients</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouve client</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm" onLoad={loader}><SaveIcon/> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">

                                        <h4 className="mb-3">Informations</h4>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom
                                                complet <span style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="fullName" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Numéro de
                                                téléphone <span style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="phone" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email
                                                address</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1"
                                                   name="email"/>
                                        </div>

                                        <h4 className="mb-3 mt-4">Adresse du client
                                        </h4>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Adresse</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="address"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Complément
                                                d'adresse</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="address1"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">ville</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="city"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Pays</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="country"/>
                                        </div>

                                        <h4 className="mb-3 mt-4">Société</h4>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="enterpriseName"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Numéro</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="enterprisePhone"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </main>
                </div>
            </form>
        </div>

    </div>)
}

export default CreateCustomer
