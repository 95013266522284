import React, {useState, useEffect} from "react"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Link} from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';


export default function DataTable({resource, column, deleteItem, update, action, element, view}) {
    const [data, setData] = useState([]),
        [detail, setDetail] = useState({}),
        [open, setOpen] = useState(false),
        [item, setItem] = useState(),
        [limit, setLimit] = useState(10),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [currentPage, setCurrentPage] = useState(1)

    const handleClickOpen = (id) => {
        setOpen(true);
        setItem(id)
    };

    const handleClose = () => {
        setOpen(false);
    };


    async function onGet() {
        let res = await resource.read(limit, page)
        if (res.status === 200) {
            setData(res.data.items)
            setDetail(res.data)
            setPages(res.data.pagesInRange)
            setCurrentPage(res.data.currentPageNumber)
        }
    }


    async function onDelete() {
        let res = await resource.delete(item)
        if (res.status === 201) {
            onGet()
            handleClose()
        }
    }

    useEffect(() => {
        onGet()
    }, [limit, page])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Souhaitez-vous vraiment supprimé l'élément
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={onDelete} className="btn btn-danger">
                        Oui
                    </button>
                    <button onClick={handleClose} className="btn btn-secondary">
                        Non
                    </button>
                </DialogActions>
            </Dialog>

            <div>
                <form>

                </form>
            </div>
            <table className="table">
                <thead>
                <tr>
                    {column && column.map((i, k) => {
                        return <th scope="col" key={k}>{i.label}</th>
                    })}

                </tr>
                </thead>
                <tbody>
                {
                    data && data.map((el, key) => {
                        return <tr className="bg-white" key={key}>
                            {column && column.map((i) => {
                                return <td>{!i.renderStyle ? !i.render ? el.hasOwnProperty(i.field) && el[i.field] : i.render.map((a, key) => {
                                    return el.hasOwnProperty(i.field) && el[i.field] ? el[i.field][a] : null
                                }) : i.renderStyle(el)}</td>
                            })}
                            {
                                action && (
                                    <td>
                                        <div className="dropdown">
                                            <MoreVertIcon id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                          aria-expanded="false" style={{cursor: "pointer"}}/>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                {
                                                    view && (<li><Link className="dropdown-item"
                                                                       to={"/" + element + "/view/" + el.id}>
                                                        <VisibilityIcon/> Visualiser</Link>
                                                    </li>)
                                                }
                                                {
                                                    update && (<li><Link className="dropdown-item"
                                                                         to={"/" + element + "/edit/" + el.id}>
                                                        <EditIcon/> Modifier</Link>
                                                    </li>)
                                                }

                                                {
                                                    deleteItem && (
                                                        <li><span className="dropdown-item" style={{cursor: "pointer"}}
                                                                  onClick={() => handleClickOpen(el.id)}>
                                                        <DeleteIcon/> Supprimer</span>
                                                        </li>)
                                                }
                                                {action(el, onGet) && action(el, onGet)}
                                            </ul>
                                        </div>
                                    </td>
                                )
                            }
                        </tr>
                    })
                }

                </tbody>
            </table>
            <div className="d-flex justify-content-between">
                <div>
                    <select className="form-select form-select-sm" aria-label="Default select example"
                            onChange={(e) => setLimit(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={detail?.previous != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.previous)}><span
                                aria-hidden="true">&laquo;</span></span>
                        </li>
                        {
                            pages && pages.map((el, key) => {
                                return <li className={currentPage == el ? "page-item active" : "page-item"} key={key}><a
                                    className="page-link"
                                    onClick={() => setPage(el)}>{el}</a>
                                </li>
                            })
                        }

                        <li className={detail?.next != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.next)}><span
                                aria-hidden="true">&raquo;</span></span>
                        </li>
                    </ul>
                </nav>

            </div>

        </div>
    )
}