import React from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";
import { useAlert } from "../../ui/Alert";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DescriptionIcon from "@material-ui/icons/Description";

function OpportunitiePage() {
    const { push } = useAlert()

    async function updateOpportunities(el, value, callback) {
        let res = await resources.opportunities.update(el, {
            status: value
        })
        if ((await res).status === 201) {
            callback()
            push({
                success: true
            })
        }
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Taxes</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Taxes</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/opportunitie/create"><AddIcon /> Nouvelle
                                taxe</Link>
                        </div>
                    </div>
Ò
                    <div className="col mt-5">
                        <DataTable column={[
                            { label: "Nom", field: "name" },
                            { label: "Categorie", field: "tax", render:['name'] },
                            { label: "Date de création", field: "createdAt" },
                        ]}
                            element={"opportunitie"}
                            action={() => {
                            }}
                            update
                            deleteItem
                            resource={resources.subtax}


                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default OpportunitiePage
