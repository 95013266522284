import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import CustomerPage from "./pages/Customer/CustomerPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateCustomer from "./pages/Customer/CreateCustomer";
import ArticlePage from "./pages/Article/ArticlePage";
import CreateArticle from "./pages/Article/CreateArticle";
import CreateBedroom from "./pages/Article/CreateBedroom";
import EstimationPage from "./pages/Estimation/EstimationPage";
import CreateEstimation from "./pages/Estimation/CreateEstimation";
import InvoicePage from "./pages/Invoice/InvoicePage";
import CreateInvoice from "./pages/Invoice/Createinvoice";
import PayementPage from "./pages/Payement/PayementPage";
import CreatePayment from "./pages/Payement/CreatePayment";
import ExpensePage from "./pages/Expense/ExpensePage";
import CreateExpense from "./pages/Expense/CreateExpense";
import UserPage from "./pages/User/UserPage";
import CreateUser from "./pages/User/CreateUser";
import ReportPage from "./pages/Report/ReportPage";
import SettingPage from "./pages/Setting/SettingPage";
import ArchivePage from "./pages/Archive/ArchivePage";
import ProjectPage from "./pages/Project/ProjectPage";
import { Alert } from "./ui/Alert";
import EditCustomer from "./pages/Customer/EditCustomer";
import EditArticle from "./pages/Article/EditArticle";
import EditUser from "./pages/User/EditUser";
import ViewArchive from "./pages/Archive/ViewArchive";
import LoginPage from "./pages/Login/LoginPage";
import CreateProject from "./pages/Project/CreateProject";
import ViewProject from "./pages/Project/ViewProject";
import ViewInvoice from "./pages/Invoice/ViewInvoice";
import EditEstimation from "./pages/Estimation/EditEstimation";
import EditInvoice from "./pages/Invoice/EditInvoice";
import OpportunitiePage from "./pages/Opportunitie/OpportunitiePage";
import CreateOpportunitie from "./pages/Opportunitie/CreateOpportunitie";
import EditOpportunitie from "./pages/Opportunitie/EditOpportunitie";
import EditBedroom from './pages/Article/EditBedroom';

function App() {
    return (
        <Router>

            <Alert>

                <Switch>

                    {/*Read*/}
                    <Route exact path="/" component={LoginPage} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/customers" component={CustomerPage} />
                    <Route exact path="/articles" component={ArticlePage} />
                    <Route exact path="/opportunities" component={OpportunitiePage} />
                    <Route exact path="/estimations" component={EstimationPage} />
                    <Route exact path="/invoices" component={InvoicePage} />
                    <Route exact path="/payments" component={PayementPage} />
                    <Route exact path="/expenses" component={ExpensePage} />
                    <Route exact path="/archives" component={ArchivePage} />
                    <Route exact path="/projects" component={ProjectPage} />
                    <Route exact path="/users" component={UserPage} />
                    <Route exact path="/reports" component={ReportPage} />
                    <Route exact path="/settings/:action" component={SettingPage} />

                    {/*Create*/}
                    <Route exact path="/customer/create" component={CreateCustomer} />
                    <Route exact path="/article/create" component={CreateArticle} />
                    <Route exact path="/bedroom/create" component={CreateBedroom} />
                    <Route exact path="/opportunitie/create" component={CreateOpportunitie} />
                    <Route exact path="/estimation/create" component={CreateEstimation} />
                    <Route exact path="/invoice/create" component={CreateInvoice} />
                    <Route exact path="/payment/create" component={CreatePayment} />
                    <Route exact path="/payment/create/:invoiceId/:rest/:customerId/:customerName"
                        component={CreatePayment} />
                    <Route exact path="/expense/create" component={CreateExpense} />
                    <Route exact path="/user/create" component={CreateUser} />
                    <Route exact path="/project/create" component={CreateProject} />


                    {/*Edit*/}
                    <Route exact path="/customer/edit/:id" component={EditCustomer} />
                    <Route exact path="/article/edit/:id" component={EditArticle} />
                    <Route exact path="/bedroom/edit/:id" component={EditBedroom} />
                    <Route exact path="/opportunitie/edit/:id" component={EditOpportunitie} />
                    <Route exact path="/estimation/edit/:id" component={EditEstimation} />
                    <Route exact path="/invoice/edit/:id" component={EditInvoice} />
                    <Route exact path="/payment/edit/:id" component={CreatePayment} />
                    <Route exact path="/expense/edit/:id" component={CreateExpense} />
                    <Route exact path="/user/edit/:id" component={EditUser} />

                    {/*View*/}
                    <Route exact path="/archive/view/:id" component={ViewArchive} />
                    <Route exact path="/project/view/:id" component={ViewProject} />
                    <Route exact path="/invoice/view/:id" component={ViewInvoice} />
                </Switch>
            </Alert>
        </Router>
    )
}

export default App
