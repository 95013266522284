import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import {Link, useHistory} from "react-router-dom";
import {useAlert} from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";

function CreateUser() {
    const [loader, setLoader] = useState(false),
        history = useHistory(), {push} = useAlert()

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            fullName: e.target.fullName.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            password: e.target.password.value,
        };

        let res = await resources.user.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <form onSubmit={onCreate}>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouvel utilisateur</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/users">Utilisateurs</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouvel utilisateur
                                        </li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-secondary btn-sm" onLoad={loader}
                                     type="submit"><SaveIcon/> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom
                                                complet <span style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="fullName" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Numéro de
                                                téléphone <span style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="phone" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Adresse
                                                e-mail <span style={{color: "red"}}>*</span></label>
                                            <input type="email" className="form-control" id="exampleInputEmail1"
                                                   name="email" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Mot de
                                                passe <span style={{color: "red"}}>*</span></label>
                                            <input type="password" className="form-control" id="exampleInputEmail1"
                                                   name="password" required/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreateUser
