import Axios from "../Axios";

const reservation = {
    create(data) {
        return Axios.post('/reservation', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/reservation?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/reservation/' + id)
    },
    update(id, data) {
        return Axios.put('/reservation/' + id, data)
    },
    delete(id) {
        return Axios.put('/reservation/' + id, {
            "active": false
        })
    },
}

export default reservation;
