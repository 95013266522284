import React, { useState, useEffect } from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";
import Select from "react-select";

function CreateOpportunitie() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        { push } = useAlert(),
        [taxs, setTaxs] = useState([]),
        [tax, setTax] = useState()

    async function onGet() {
        let cus = await resources.tax.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTaxs(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            tax: tax,
            name: e.target.name.value
        };

        let res = await resources.subtax.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouvelle taxe</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/opportunitie">Taxes</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouvelle
                                            taxe
                                        </li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><SaveIcon /> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Categorie <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setTax(e.value)} options={taxs} />
                                            </div>
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                required name="name" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreateOpportunitie
