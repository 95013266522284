import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";
import SendIcon from '@material-ui/icons/Send';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import {useAlert} from "../../ui/Alert";

function EstimationPage() {
    const {push} = useAlert()

    async function updateEstimation(el, value, callback) {
        let res = await resources.estimation.update(el, {
            status: value
        })
        if ((await res).status === 201) {
            callback()
            push({
                success: true
            })
        }
    }

    async function toInvoice(value, callback) {
        let data = {
            customer: value.customer.id,
            total: value.total,
            discount: value.discount,
            dueDate: value.dueDate,
            items: value.estimationItems
        }
        let res = await resources.invoice.create(data)
        if (res.status === 201) {
            updateEstimation(value.id, 4, callback)
        } else {
            push({
                error: true
            })
        }
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Dévis</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Devis</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/estimation/create"><AddIcon/> Nouveau
                                dévis</Link>
                        </div>
                    </div>
                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Client", field: "customer", render: ["fullName"]},
                            {label: "Total", field: "total"},
                            {
                                label: "Status", field: "status", renderStyle: (data) => {
                                    return <p>{data.status === 0 &&
                                    <span className="badge bg-warning text-dark">brouillon</span>}
                                        {data.status === 1 &&
                                        <span className="badge bg-primary">envoyer</span>}
                                        {data.status === 2 &&
                                        <span className="badge bg-success">accepter</span>}
                                        {data.status === 3 &&
                                        <span className="badge bg-danger">rejeter</span>}
                                        {data.status === 4 &&
                                        <span className="badge bg-secondary">tranformer en facture</span>}</p>
                                }
                            },
                            {label: "Date d'echenace", field: "dueDate"},
                        ]}
                                   element={"estimation"}
                                   resource={resources.estimation}
                                   action={(data, callback) => {
                                       return <>
                                           <li onClick={() => updateEstimation(data.id, 1, callback)}><span
                                               className="dropdown-item" style={{cursor: "pointer"}}>
                                                        <SendIcon/> Marqué comme envoyer</span>
                                           </li>
                                           <li onClick={() => updateEstimation(data.id, 2, callback)}><span
                                               className="dropdown-item" style={{cursor: "pointer"}}>
                                                        <CheckCircleIcon/> Marqué comme accepter</span>
                                           </li>
                                           <li onClick={() => updateEstimation(data.id, 3, callback)}><span
                                               className="dropdown-item" style={{cursor: "pointer"}}>
                                                        <CancelIcon/> Marqué comme rejeter</span>
                                           </li>
                                           <li onClick={() => toInvoice(data, callback)}><span className="dropdown-item"
                                                                                               style={{cursor: "pointer"}}>
                                                        <DescriptionIcon/> Transformer en facture</span>
                                           </li>
                                       </>
                                   }}
                                   update
                                   deleteItem/>
                    </div>


                </main>
            </div>
        </div>

    </div>)
}

export default EstimationPage
