import React from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import resources from "../../services";
import DataTable from "../../composents/DataTable";

function ProjectPage() {


    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Reservations</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reservations</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/project/create"><AddIcon /> Nouvelle reservation</Link>
                        </div>
                    </div>
                    <div className="col mt-5">
                        <DataTable column={[
                            { label: "Client", field: "customer", render: ["fullName"] },
                            { label: "Date de début", field: "dateBegin" },
                            { label: "Date de fin", field: "dateEnd" },
                            { label: "Date de la reservation", field: "createdAt" },
                        ]}
                            element={"project"}
                            resource={resources.reservation}
                            action={() => { }}
                            view
                            deleteItem />
                    </div>

                </main>
            </div>
        </div>

    </div>)
}

export default ProjectPage
