import React, { useState, useEffect } from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";
import Select from "react-select";

function CreateExpense() {
    const [loader, setLoader] = useState(false),
        history = useHistory(), { push } = useAlert(),
        [customers, setCustomers] = useState([]),
        [categories, setCategories] = useState([]),
        [category, setCategory] = useState(),
        [taxs, setTaxs] = useState([]),
        [tax, setTax] = useState(),
        [customer, setCustomer] = useState(),
        [type, setType] = useState(null);

    async function onGet() {
        let pro = await resources.category.read()

        if (pro.status === 200) {
            let a = []
            pro.data.items.forEach((el) => {
                if (el.status !== 2) {
                    a.push({
                        value: el.id,
                        label: el.name,
                    })
                }

            })
            setCategories(a)
        }



        let ta = await resources.subtax.read()
        if (ta.status === 200) {
            let a = []
            ta.data.items.forEach((el) => {
                if (el.status !== 2) {
                    a.push({
                        value: el.id,
                        label: el.name,
                    })
                }

            })
            setTaxs(a)
        }

        let cus = await resources.customer.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.fullName,
                })
            })
            setCustomers(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            customer: customer,
            amount: e.target.amount.value,
            type: type,
            note: e.target.note.value,
            dateExpense: e.target.dateExpense.value
        };

        if (type == 0) {
            object.tax = tax
        } else {
            object.category = category
        }

        let res = await resources.expense.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
            history.goBack()
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouvelle dépense</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/expenses">Dépenses</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouvelle dépense</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><SaveIcon /> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Type de dépense <span
                                                style={{ color: "red" }}>*</span></label>
                                            <select class="form-select" aria-label="Default select example" onChange={(e) => setType(e.target.value)}>
                                                <option >Choisir...</option>
                                                <option value={0}>Taxes</option>
                                                <option value={1}>Divers</option>
                                            </select>
                                        </div>


                                        {
                                            type == 0 && (<div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Taxes <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setTax(e.value)} required
                                                    options={taxs} />
                                            </div>)
                                        }

                                        {
                                            type == 1 && (<div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Catégorie <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setCategory(e.value)} required
                                                    options={categories} />
                                            </div>)
                                        }


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                name="dateExpense" required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Montant <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                required name="amount" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Client</label>
                                            <Select onChange={(e) => setCustomer(e.value)} options={customers} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                className="form-label">Note</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                rows="3" name="note"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreateExpense
