import customer from "./customer"
import article from "./article"
import estimation from "./estimation"
import invoice from "./invoice";
import payment from "./payment";
import expense from "./expense"
import category from "./category"
import user from "./user"
import archive from "./archive"
import auth from "./auth"
import project from "./project"
import chart from "./chart"
import report from "./report"
import opportunities from "./opportunities"
import tax from "./tax"
import bedroom from "./bedroom"
import reservation from "./reservation"
import subtax from "./subtax"



const resources = {
    customer,
    article,
    estimation,
    invoice,
    payment,
    expense,
    category,
    user,
    archive,
    auth,
    project,
    chart,
    report,
    opportunities,
    tax,
    bedroom,
    reservation,
    subtax
};
export default resources;
